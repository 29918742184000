// ================报警系统================

export default [
  {
    path: '/alarm',
    redirect: '/alarm/alarmType',
    component: () => import('@/components/home/home.vue'),
    children: [
      {
        path: 'alarmType',
        name: 'alarmType',
        component: () => import('@/view/alarm_system/alarmType.vue'),
      },
      {
        path: 'alarmSceneSingleRule',
        name: 'alarmSceneSingleRule',
        component: () => import('@/view/alarm_system/scenario_rules/index.vue'),
      },
      {
        path: 'alarmRecord',
        name: 'alarmRecord',
        component: () => import('@/view/alarm_system/alarm_record/index.vue'),
      },
    ],
  },
]
