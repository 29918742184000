export default {
  namespaced: true,
  state: {
    sellDialogVisible: false,
    produceDialogVisible: false,
    pipeDialogVisible: false,
  },
  mutations: {
    // ========打开营销驾驶舱dialog========
    openSellDialog(state) {
      state.sellDialogVisible = true
    },
    // ========关闭营销驾驶舱dialog========
    closeSellDialog(state) {
      state.sellDialogVisible = false
    },
    // ========打开生产驾驶舱dialog========
    openProduceDialog(state) {
      state.produceDialogVisible = true
    },
    // ========关闭生产驾驶舱dialog========
    closeProduceDialog(state) {
      state.produceDialogVisible = false
    },
    // ========打开管网驾驶舱dialog========
    openPipeDialog(state) {
      state.pipeDialogVisible = true
    },
    // ========关闭管网驾驶舱dialog========
    closePipeDialog(state) {
      state.pipeDialogVisible = false
    },
  },
}
