export default {
  namespaced: true,
  state: {
    allMenuList: [], //所有菜单数据
    menuList: [], //顶级菜单数组
    subMenuList: [], //子菜单数据  (左侧菜单数据)
    url: 'revenue', //顶级菜单路径
    id: '54', //顶级菜单的id
    chooseItemUrl: '', //选中的选项卡路径
    currentTab: {
      // 当前激活tab
      id: '',
      name: '',
      url: '',
    },
    editableTabs: [],
  },
  mutations: {
    // ========el-tab新加标签========
    addTab(state, menu) {
      let exist = state.editableTabs.find((item) => item.id == menu.id)

      if (exist === undefined) {
        // 新页面不在tabs中
        state.editableTabs.push({
          id: menu.id.toString(),
          name: menu.name,
          url: menu.url,
        })
      }

      state.currentTab.id = menu.id.toString()
      state.currentTab.name = menu.name
      state.currentTab.url = menu.url
    },
    // ========删除单个选项卡========
    deleteTab(state, url) {
      state.editableTabs = state.editableTabs.filter((item) => item.url !== url)
      if (url === state.currentTab.url) {
        //删除的是当前激活页面
        state.currentTab.id = state.editableTabs[0].id.toString()
        state.currentTab.url = state.editableTabs[0].url
      }
    },
    // ========删除所有选项卡,只保留当前打开的选项卡========
    removeAll(state) {
      state.editableTabs = state.editableTabs.filter(
        (item) => item.url == state.currentTab.url
      )
    },
    // ========用户退出系统时，清除所有视图选项卡========
    clearAll(state) {
      state.editableTabs = []
    },
    // ========更新选项卡========
    updateCurrentTab(state, url) {
      const tab = state.editableTabs.find((item) => item.url == url)
			if (tab == undefined) return
      state.currentTab.id = tab.id
      state.currentTab.url = tab.url
    },
    // ========初始化选项卡========
    initTab(state) {
      state.currentTab = {
        id: '70',
        name: '数据分析',
        url: 'data_analysis',
      },
      state.editableTabs = [
        {
          id: '70',
          name: '数据分析',
          url: 'data_analysis',
        },
      ]
    },
    getTopMenu(state, value) {
      //获取顶级菜单列表信息
      state.menuList = value
    },
    setSubMenu(state, value) {
      //修改左侧菜单数据
      state.subMenuList = value
    },
    toRouter(state, value) {
      //返回主页面
      state.url = value.url
      state.id = value.id
      state.chooseItemUrl = value.chooseItemUrl
      state.editableTabsValue = value.editableTabsValue
      state.editableTabs = value.editableTabs
    },
    initState(state) {
      //state数据初始化
      state.url = 'revenue'
      state.id = 54
      state.chooseItemUrl = 'data_analysis'
      state.editableTabsValue = '70'
      state.editableTabs = [
        {
          menuListId: 54,
          url: 'revenue',
          name: '70',
          suburl: 'data_analysis',
          title: '数据分析',
        },
      ]
    },
    // ========获取角色的全部菜单信息========
    loadAllMenuList(state, value) {
      state.allMenuList = value
      localStorage.setItem('allMenuList', JSON.stringify(value))
    },
  },
}
