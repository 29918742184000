// ================营收系统================

export default [
  {
    path: '/revenue',
    redirect: '/revenue/data_analysis',
    component: () => import('@/components/home/home.vue'),
    children: [
      //统计分析
      {
        path: 'data_analysis',
        name: 'data_analysis',
        component: () => import('@/view/revenue/statistic_analysis/index.vue'),
      },
      {
        path: 'village_level_user_manage',
        name: 'village_level_user_manage',
        component: () =>
          import(
            '@/view/revenue/userProfile/village_level_user_manage/index.vue'
          ),
      },
      {
        path: 'user_manage',
        name: 'user_manage',
        component: () =>
          import('@/view/revenue/userProfile/user_manage/index.vue'),
      },
      {
        path: 'changeMeterRecords',
        name: 'changeMeterRecords',
        component: () =>
          import('@/view/revenue/userProfile/changeMeterRecords.vue'),
      },
      {
        path: 'accountCancellationRecords',
        name: 'accountCancellationRecords',
        component: () =>
          import('@/view/revenue/userProfile/accountCancellationRecords.vue'),
      },
      // 收费管理
      {
        path: 'charge_manage',
        name: 'charge_manage',
        component: () =>
          import('@/view/revenue/charge_manage/charge_manage/index.vue'),
      },
      {
        path: 'payment_records',
        name: 'payment_records',
        component: () =>
          import('@/view/revenue/charge_manage/payment_records/index.vue'),
      },
      {
        path: 'reconciliationManage',
        name: 'reconciliationManage',
        component: () =>
          import('@/view/revenue/charge_manage/reconciliationManage.vue'),
      },
      {
        path: 'refundRecords',
        name: 'refundRecords',
        component: () =>
          import('@/view/revenue/charge_manage/refundRecords.vue'),
      },
      {
        path: 'notesManage',
        name: 'notesManage',
        component: () => import('@/view/revenue/charge_manage/notesManage.vue'),
      },
      {
        path: 'reduce_fee_record',
        name: 'reduce_fee_record',
        component: () =>
          import('@/view/revenue/charge_manage/reduce_fee_record/index.vue'),
      },
      {
        path: 'WaterConsumptionAnalysis',
        name: 'WaterConsumptionAnalysis',
        component: () =>
          import('@/view/revenue/charge_manage/waterConsumptionAnalysis.vue'),
      },
      {
        path: 'RepayMoneyRecord',
        name: 'RepayMoneyRecord',
        component: () =>
          import('@/view/revenue/charge_manage/repayMoneyRecord.vue'),
      },
      // 账单管理
      {
        path: 'water_bill',
        name: 'water_bill',
        component: () =>
          import('@/view/revenue/bill_manage/water_bill/index.vue'),
      },
			// 村镇级账单管理
			{
			  path: 'village_level_water_bill',
			  name: 'village_level_water_bill',
			  component: () =>
			    import('@/view/revenue/bill_manage/village_level_water_bill/index.vue'),
			},
      // 账单计算
      {
        path: 'bill_calculation',
        name: 'bill_calculation',
        component: () =>
          import('@/view/revenue/bill_manage/bill_calculation/index.vue'),
      },
      // 营收报表
      {
        path: 'real_time',
        name: 'real_time',
        component: () => import('@/view/revenue/report/real_time/index.vue'),
      },
      {
        path: 'receivableReport',
        name: 'receivableReport',
        component: () => import('@/view/revenue/report/receivableReport'),
      },
      {
        path: 'netReceiptsReports',
        name: 'netReceiptsReports',
        component: () => import('@/view/revenue/report/netReceiptsReports'),
      },
      {
        path: 'dataReporting',
        name: 'dataReporting',
        component: () => import('@/view/revenue/report/dataReporting'),
      },
      {
        path: 'dataDetailReporting',
        name: 'dataDetailReporting',
        component: () => import('@/view/revenue/report/dataDetailReporting'),
      },
      {
        path: 'waterUseErrorReport',
        name: 'waterUseErrorReport',
        component: () => import('@/view/revenue/report/waterUseErrorReport'),
      },
      {
        path: 'seatStatisticsReporting',
        name: 'seatStatisticsReporting',
        component: () =>
          import('@/view/revenue/report/seatStatisticsReporting'),
      },
      {
        path: 'businessDepartmentRevenueReporting',
        name: 'businessDepartmentRevenueReporting',
        component: () =>
          import('@/view/revenue/report/businessDepartmentRevenueReporting'),
      },
      {
        path: 'variousDepartmentsAppealsHandlingReport',
        name: 'variousDepartmentsAppealsHandlingReport',
        component: () =>
          import(
            '@/view/revenue/report/variousDepartmentsAppealsHandlingReport'
          ),
      },
      {
        path: 'waterCompanyDemandsReporting',
        name: 'waterCompanyDemandsReporting',
        component: () =>
          import('@/view/revenue/report/waterCompanyDemandsReporting'),
      },
      // 催缴关阀
      {
        path: 'close_valve',
        name: 'close_valve',
        component: () =>
          import('@/view/revenue/bill_manage/close_valve/index.vue'),
      },
      // 水价管理
      {
        path: 'waterPriceType',
        name: 'waterPriceType',
        component: () => import('@/view/revenue/water_price/waterPriceType'),
      },
      {
        path: 'waterPrice',
        name: 'waterPrice',
        component: () => import('@/view/revenue/water_price/waterPrice'),
      },
      {
        path: 'waterUserPrice',
        name: 'waterUserPrice',
        component: () => import('@/view/revenue/water_price/waterUserPrice'),
      },
      {
        path: 'lateFeeManage',
        name: 'lateFeeManage',
        component: () => import('@/view/revenue/late_fee/index.vue'),
      },
      {
        path: 'report_installation',
        name: 'report_installation',
        component: () =>
          import('@/view/revenue/report/report_installation/index.vue'),
      },
      // 抄表管理
      {
        path: 'meter_read',
        name: 'meter_read',
        component: () => import('@/view/revenue/meter_read/index.vue'),
      },
      // 诚信度评价
      {
        path: 'credit_level',
        name: 'credit_level',
        component: () => import('@/view/revenue/credit_level/index.vue'),
      },
    ],
  },
]
