// ================综合管控系统================

export default [
  {
    path: '/comprehensive_control',
    redirect: '/comprehensive_control/inspectMould',
    component: () => import('@/components/home/home.vue'),
    children: [
      {
        path: 'inspectMould',
        name: 'inspectMould',
        component: () =>
          import(
            '@/view/comprehensive_control/mobile_inspection/inspectMould.vue'
          ),
      },
      {
        path: 'inspectTask',
        name: 'inspectTask',
        component: () =>
          import(
            '@/view/comprehensive_control/mobile_inspection/inspectTask.vue'
          ),
      },
      // 维修管理
      {
        path: 'repairPlan',
        name: 'repairPlan',
        component: () =>
          import(
            '@/view/comprehensive_control/repair_management/repair_plan/index.vue'
          ),
      },
      {
        path: 'repairTask',
        name: 'repairTask',
        component: () =>
          import(
            '@/view/comprehensive_control/repair_management/repair_task/index.vue'
          ),
      },
      // 管道
      {
        path: 'pipelineNetworkOrderOverviewReporting',
        name: 'pipelineNetworkOrderOverviewReporting',
        component: () =>
          import(
            '@/view/comprehensive_control/pipeline/pipelineNetworkOrderOverviewReporting'
          ),
      },
      {
        path: 'pipelineNetworkMonthDetailReport',
        name: 'pipelineNetworkMonthDetailReport',
        component: () =>
          import(
            '@/view/comprehensive_control/pipeline/pipelineNetworkMonthDetailReport'
          ),
      },
      {
        path: 'pipelineOperationReporting',
        name: 'pipelineOperationReporting',
        component: () =>
          import(
            '@/view/comprehensive_control/pipeline/pipelineOperationReporting'
          ),
      },
      // 生产
      {
        path: 'companyProduceOperationMonthReporting',
        name: 'companyProduceOperationMonthReporting',
        component: () =>
          import(
            '@/view/comprehensive_control/produce/companyProduceOperationMonthReporting'
          ),
      },
      {
        path: 'waterworksProduceOperationReporting',
        name: 'waterworksProduceOperationReporting',
        component: () =>
          import(
            '@/view/comprehensive_control/produce/waterworksProduceOperationReporting'
          ),
      },
      {
        path: 'waterworksProduceOperationDetailReporting',
        name: 'waterworksProduceOperationDetailReporting',
        component: () =>
          import(
            '@/view/comprehensive_control/produce/waterworksProduceOperationDetailReporting'
          ),
      },
      {
        path: 'waterUsePrediction',
        name: 'waterUsePrediction',
        component: () =>
          import('@/view/comprehensive_control/produce/waterUsePrediction'),
      },
      {
        path: 'companyProduceOperationReporting',
        name: 'companyProduceOperationReporting',
        component: () =>
          import(
            '@/view/comprehensive_control/produce/companyProduceOperationReporting'
          ),
      },
      // 压力
      {
        path: 'pressurePassRateDayReporting',
        name: 'pressurePassRateDayReporting',
        component: () =>
          import(
            '@/view/comprehensive_control/pressure/pressurePassRateDayReporting'
          ),
      },
      {
        path: 'pressurePassRateMonthReporting',
        name: 'pressurePassRateMonthReporting',
        component: () =>
          import(
            '@/view/comprehensive_control/pressure/pressurePassRateMonthReporting'
          ),
      },
      {
        path: 'real_time_pressure',
        name: 'real_time_pressure',
        component: () =>
          import(
            '@/view/comprehensive_control/pressure/real_time_pressure/index.vue'
          ),
      },
      {
        path: 'pressureThematicAnalysis',
        name: 'pressureThematicAnalysis',
        component: () =>
          import(
            '@/view/comprehensive_control/pressure/pressureThematicAnalysis'
          ),
      },
      // 水量

      {
        path: 'flow_analysis_report',
        name: 'flow_analysis_report',
        component: () =>
          import('@/view/comprehensive_control/flow_analysis_report/index.vue'),
      },
      // 水质
      {
        path: 'waterQualityIndexReporting',
        name: 'waterQualityIndexReporting',
        component: () =>
          import(
            '@/view/comprehensive_control/water_quality/waterQualityIndexReporting'
          ),
      },
      {
        path: 'waterQuantityMonitorReporting',
        name: 'waterQuantityMonitorReporting',
        component: () =>
          import(
            '@/view/comprehensive_control/water_quality/waterQuantityMonitorReporting'
          ),
      },
      {
        path: 'waterQuantityThematicAnalysis',
        name: 'waterQuantityThematicAnalysis',
        component: () =>
          import(
            '@/view/comprehensive_control/water_quality/waterQuantityThematicAnalysis'
          ),
      },
      // 水厂
      {
        path: 'waterFactoryComprehensiveReporting',
        name: 'waterFactoryComprehensiveReporting',
        component: () =>
          import(
            '@/view/comprehensive_control/water_factory/waterFactoryComprehensiveReporting'
          ),
      },
      {
        path: 'waterFactoryProduceReporting',
        name: 'waterFactoryProduceReporting',
        component: () =>
          import(
            '@/view/comprehensive_control/water_factory/waterFactoryProduceReporting'
          ),
      },
    ],
  },
]
