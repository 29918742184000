// ================应用支撑===============

export default [
    {
        path: '/system_manage',
        redirect: '/system_manage/userManage',
        component: () => import('@/components/home/home.vue'),
        children: [
            // 系统管理
            {
                //用户管理
                path: 'userManage',
                name: 'userManage',
                component: () =>
                    import(
                        '@/view/application_support/system_manage/user_manage/index.vue'
                        ),
            },
            {
                // 角色管理
                path: 'roleManage',
                name: 'roleManage',
                component: () =>
                    import(
                        '@/view/application_support/system_manage/role_manage/index.vue'
                        ),
            },
            {
                // 角色管理
                path: 'system_post',
                name: 'system_post',
                component: () =>
                    import('@/view/application_support/system_manage/systemPost.vue'),
            },
            {
                // 行政区划
                path: 'system_ad',
                name: 'system_ad',
                component: () =>
                    import(
                        '@/view/application_support/system_manage/system_ad/index.vue'
                        ),
            },

            {
                // 菜单管理
                path: 'menu_manage',
                name: 'menu_manage',
                component: () =>
                    import(
                        '@/view/application_support/system_manage/menu_manage/index.vue'
                        ),
            },

            {
                // 数据字典
                path: 'SystemDataDict',
                name: 'SystemDataDict',
                component: () =>
                    import('@/view/application_support/system_manage/systemDataDict.vue'),
            },
            {
                // 组织机构管理
                path: 'system_org',
                name: 'system_org',
                component: () =>
                    import(
                        '@/view/application_support/system_manage/system_org/index.vue'
                        ),
            },
            {
                // 接口管理
                path: 'system_api',
                name: 'system_api',
                component: () =>
                    import(
                        '@/view/application_support/system_manage/system_api/index.vue'
                        ),
            },
            // 设备管理
            {
                path: 'deviceInfo',
                name: 'deviceInfo',
                component: () =>
                    import('@/view/application_support/device_manage/deviceInfo.vue'),
            },
            // 管网资产配置
            {
                path: 'pipeLineAssetConfig',
                name: 'pipeLineAssetConfig',
                component: () =>
                    import(
                        '@/view/application_support/pipeLineAssetManager/pipeLineAssetConfig.vue'
                        ),
            },
            // 供水关系
            {
                path: 'measure_pipe',
                name: 'measure_pipe',
                component: () =>
                    import(
                        '@/view/application_support/water_provide/measure_pipe/index.vue'
                        ),
            },
            {
                path: 'measure_point',
                name: 'measure_point',
                component: () =>
                    import(
                        '@/view/application_support/water_provide/measure_point/index.vue'
                        ),
            },
            {
                path: 'pipe_leakage',
                name: 'pipe_leakage',
                component: () =>
                    import(
                        '@/view/application_support/water_provide/pipe_leakage/index.vue'
                        ),
            },
            //漏损分析
            {
                path: 'pipe_leakage_analysis',
                name: 'pipe_leakage_analysis',
                component: () =>
                    import(
                        '@/view/application_support/water_provide/pipe_leakage_analysis/index.vue'
                        ),
            },
            {
                path: 'flow_manage',
                name: 'flow_manage',
                component: () => import('@/view/application_support/flow_manage/index.vue'),
            },
        ],
    },
]
