// ================个人工作台===============

export default [
  {
    path: "/personal_workbench",
    redirect: "/personal_workbench/homepage",
    component: () => import("@/components/home/home.vue"),
    children: [
      {
        path: "alarmManagement",
        name: "alarmManagement",
        component: () => import("@/view/personal_workbench/alarmManagement")
      },
      {
        path: "homepage",
        name: "homepage",
        component: () => import("@/view/personal_workbench/home_page/index.vue")
      },
      {
        path: "matterManagement",
        name: "matterManagement",
        component: () => import("@/view/personal_workbench/matterManagement")
      },
      {
        path: "moduleAccess",
        name: "moduleAccess",
        component: () => import("@/view/personal_workbench/moduleAccess")
      },
      {
        path: "notificationAnnouncementManagement",
        name: "notificationAnnouncementManagement",
        component: () =>
          import(
            "@/view/personal_workbench/notificationAnnouncementManagement"
            )
      },
      {
        path: "pushConfiguration",
        name: "pushConfiguration",
        component: () => import("@/view/personal_workbench/pushConfiguration")
      },
      {
        path: "remindMessageManagement",
        name: "remindMessageManagement",
        component: () =>
          import("@/view/personal_workbench/remindMessageManagement")
      },
      {
        path: "systemMessageConfig",
        name: "systemMessageConfig",
        component: () =>
          import("@/view/personal_workbench/systemMessageConfig")
      }
    ]
  }
];
