// ================数据填报================

export default [
  {
    path: '/DataReport',
    redirect: '/DataReport/dataIndex',
    component: () => import('@/components/home/home.vue'),
    children: [
      // 首页
      {
        path: 'dataIndex',
        name: 'dataIndex',
        component: () => import('@/view/dataReport/dataIndex.vue'),
      },
      // 接入统计
      {
        path: 'accessStatistics',
        name: 'accessStatistics',
        component: () => import('@/view/dataReport/accessStatistics.vue'),
      },
      // 用户基本信息
      {
        path: 'userBaseInfo',
        name: 'userBaseInfo',
        component: () => import('@/view/dataReport/userBaseInfo.vue'),
      },
      // 设备接入
      {
        path: 'deviceAccess',
        name: 'deviceAccess',
        component: () => import('@/view/dataReport/deviceAccess.vue'),
      },
    ],
  },
]
