// 报装系统
export default [
  {
    path: '/InstallationSystem',
    redirect: '/InstallationSystem/approve',
    component: () => import('@/components/home/home.vue'),
    children: [
      // 项目挂起
      {
        path: 'approve',
        name: 'approve',
        component: () => import('@/view/installationSystem/approve.vue'),
      },
      // 项目立户
      {
        path: 'invest',
        name: 'invest',
        component: () => import('@/view/installationSystem/invest.vue'),
      },
      // 首页
      {
        path: 'installationIndex',
        name: 'installationIndex',
        component: () =>
          import('@/view/installationSystem/installationIndex.vue'),
      },
      // 设计管理
      {
        path: 'design',
        name: 'design',
        component: () => import('@/view/installationSystem/design.vue'),
      },
      // 进度管理
      {
        path: 'progress',
        name: 'progress',
        component: () => import('@/view/installationSystem/progress.vue'),
      },
      // 物资管理
      // 供应商管理
      {
        path: 'supplier',
        name: 'supplier',
        component: () =>
          import('@/view/installationSystem/materialManagement/supplier.vue'),
      },
      // 仓库管理
      {
        path: 'warehouse',
        name: 'warehouse',
        component: () =>
          import('@/view/installationSystem/materialManagement/warehouse.vue'),
      },
      // 物资分类
      {
        path: 'classification',
        name: 'classification',
        component: () =>
          import(
            '@/view/installationSystem/materialManagement/classification.vue'
          ),
      },
      // 物资信息
      {
        path: 'material',
        name: 'material',
        component: () =>
          import('@/view/installationSystem/materialManagement/material.vue'),
      },
      // 报装受理
      {
        path: 'acceptance',
        name: 'acceptance',
        component: () => import('@/view/installationSystem/acceptance.vue'),
      },
      // 出入库管理
      // 物资库存

      {
        path: 'stock',
        name: 'stock',
        component: () =>
          import('@/view/installationSystem/inOutmanage/stock.vue'),
      },
      // 入库管理
      {
        path: 'inwarehouse',
        name: 'inwarehouse',
        component: () =>
          import('@/view/installationSystem/inOutmanage/inwarehouse.vue'),
      },
      // 出库管理
      {
        path: 'outwarehouse',
        name: 'outwarehouse',
        component: () =>
          import('@/view/installationSystem/inOutmanage/outwarehouse.vue'),
      },
      // 项目催办
      {
        path: 'buildEquip',
        name: 'buildEquip',
        component: () => import('@/view/installationSystem/buildEquip.vue'),
      },
      // 延期审批
      {
        path: 'devicePage',
        name: 'devicePage',
        component: () => import('@/view/installationSystem/devicePage.vue'),
      },
      // 自定义驳回
      {
        path: 'buyPlan',
        name: 'buyPlan',
        component: () => import('@/view/installationSystem/buyPlan.vue'),
      },
      // 分期缴费管理
      {
        path: 'inRecord',
        name: 'inRecord',
        component: () => import('@/view/installationSystem/inRecord.vue'),
      },
    ],
  },
]
