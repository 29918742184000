import Vue from 'vue'
import Router from 'vue-router'

import alarm from './modules/alarm'
import control from './modules/control'
import dataReport from './modules/dataReport'
import installationSystem from './modules/installationSystem'
import online from './modules/online'
import revenue from './modules/revenue'
import system from './modules/system'
import workbench from './modules/workbench'

Vue.use(Router)

const originalPush = Router.prototype.push
Router.prototype.push = function push(location, onResolve, onReject) {
  if (onResolve || onReject) {
    return originalPush.call(this, location, onResolve, onReject)
  }
  return originalPush.call(this, location).catch((err) => err)
}

const router = new Router({
  mode: 'history',
  routes: [
    {
      //登录页面
      path: '/',
      name: 'login',
      component: () => import('@/view/login'),
    },
    ...revenue,
    ...control,
    ...alarm,
    ...online,
    ...workbench,
    ...system,
    ...dataReport,
    ...installationSystem,
    {
      //驾驶舱
      path: '/cockpit',
      name: 'cockpit',
      component: () => import('@/view/cockpit/index.vue'),
    },
    {
      //GIS
      path: '/GIS',
      name: 'gis',
      component: () => import('@/view/gis/index.vue'),
    },
  ],
})

// 添加 afterEach 钩子以保存当前路由
router.afterEach((to, from) => {
  const routeInfo = {
	fullPath: to.fullPath,
	name: to.name,
	params: to.params,
	query: to.query,
  };
  sessionStorage.setItem('lastRoute', JSON.stringify(routeInfo)); // 将当前路由保存到 sessionStorage
	//如果页面从驾驶舱或GIS页面返回，当做页面重刷新（因为需要系统内页面跳转到相应页面）
	//cockpit 和 gis 为驾驶舱页面和GIS页面在router中定义的name
  if (from.name == 'cockpit' || from.name == 'gis') {
  	location.reload(true)
  }
});

export default router;
