<template>
  <div class='main'>
    <router-view></router-view>

  </div>
</template>

<script>
export default {
  name: 'app',
}
</script>
<style scoped>
.main {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  overflow: hidden;
}

</style>
