import {
  getLayerByParentId,
  getProjectDto,
  getSystemAdLayerByAdId,
} from '@/api/gis.js'

export default {
  namespaced: true,
  state: {
    mapLine: [],
    mapInfo: [],
    mapMarker: [],
    leave: 3, //控制层级
    placeId: '1', //行政区划id    getProjectDto的第一个参数
    checkboxGroup: '39,45', //管网资产 id字符串   getProjectDto的第二个参数
    waterMeter: [
      //管网资产数据
      {
        id: 39,
        iconPath:
          'http://120.224.15.80:4063/png/4d83f605-100d-4738-9ce6-b18bab502a7c.png',
        iconAlarmPath:
          'http://120.224.15.80:4063/png/7b95180a-6fc6-4e7d-a264-c82a2ef2ede9.png',
        name: '农村联户表井',
      },
      {
        id: 45,
        iconPath:
          'http://120.224.15.80:4063/png/5ccb9c75-9197-4fb6-8912-9a4bf67dbe1a.png',
        iconAlarmPath:
          'http://120.224.15.80:4063/png/c0cf1b91-7de6-4b85-ab10-8704ac3c02a9.png',
        name: '水质监控点',
      },
    ],
  },
  mutations: {
    MAP_LINE(state, payload) {
      //设置地图 管线信息(暂未使用)
      state.mapLine = payload
    },
    MAP_INFO(state, payload) {
      //设置地图 区域信息
      state.mapInfo = payload
    },
    MAP_MARKER(state, payload) {
      //设置地图 地方标记点
      state.mapMarker = payload
    },
    setLeave(state, val) {
      //设置地图 层级
      if (val === undefined) {
        state.leave += 1
      } else {
        state.leave = val
      }
    },
    setPlaceId(state, val) {
      //设置行政区划id
      state.placeId = val
    },
    setCheckboxGroup(state, val) {
      //设置 管网资产 数据
      state.checkboxGroup = val
    },
    setwaterMeter(state, val) {
      //设置管网资产数据
      state.waterMeter = val
    },
    initState(state) {
      ;(state.mapLine = []),
        (state.mapInfo = []),
        (state.mapMarker = []),
        (state.leave = 3), //控制层级
        (state.placeId = '1'), //行政区划id    getProjectDto的第一个参数
        (state.checkboxGroup = '39,45'), //管网资产 id字符串   getProjectDto的第二个参数
        (state.waterMeter = [
          //管网资产数据
          {
            id: 39,
            iconPath:
              'http://120.224.15.80:4063/png/4d83f605-100d-4738-9ce6-b18bab502a7c.png',
            iconAlarmPath:
              'http://120.224.15.80:4063/png/7b95180a-6fc6-4e7d-a264-c82a2ef2ede9.png',
            name: '农村联户表井',
          },
          {
            id: 45,
            iconPath:
              'http://120.224.15.80:4063/png/5ccb9c75-9197-4fb6-8912-9a4bf67dbe1a.png',
            iconAlarmPath:
              'http://120.224.15.80:4063/png/c0cf1b91-7de6-4b85-ab10-8704ac3c02a9.png',
            name: '水质监控点',
          },
        ])
    },
  },
  actions: {
    /* gis地图管线查询 (暂未启用)*/
    async getSystemAdLayerByAdId({ commit }, val) {
      let res = await getSystemAdLayerByAdId(val) //25
      // let data = res.data.object.data        //==============此处数据不对
      // commit('MAP_LINE', data);
    },
    /* 根据行政区划parentId查询儿子区域图层信息 */
    async getLayerByParentId({ commit }, val) {
      let res = await getLayerByParentId(val)
      let data = res.data
      commit('MAP_INFO', data)
    },
    /* 地图上富文本标签显示 */
    async getProjectDto({ commit }, { val1, val2 }) {
      let res = await getProjectDto({ adId: val1, typeId: val2 })
      let data = res.data
      commit('MAP_MARKER', data)
      if (val1 !== undefined) {
        commit('setPlaceId', val1)
      }
    },
  },
}
