// ================网上营业厅================

export default [
  {
    path: '/online_business',
    redirect: '/online_business/messageManagement',
    component: () => import('@/components/home/home.vue'),
    children: [
      {
        // 留言管理
        path: 'message_control',
        name: 'message_control',
        component: () =>
          import('@/view/online_business/message_control/index.vue'),
      },
      // 业务办理
      {
        // 过户更名
        path: 'transferOwnership',
        name: 'transferOwnership',
        component: () =>
          import(
            '@/view/online_business/businessProcess/transferOwnership.vue'
          ),
      },
      {
        //"低保申报"
        path: 'DeclarationSubsistenceAllowance',
        name: 'DeclarationSubsistenceAllowance',
        component: () =>
          import(
            '@/view/online_business/businessProcess/DeclarationSubsistenceAllowance.vue'
          ),
      },
      {
        //"水表报停"
        path: 'WaterMeterShutdown',
        name: 'WaterMeterShutdown',
        component: () =>
          import(
            '@/view/online_business/businessProcess/WaterMeterShutdown.vue'
          ),
      },
      {
        //"水表复装"
        path: 'WaterMeterReinstallation',
        name: 'WaterMeterReinstallation',
        component: () =>
          import(
            '@/view/online_business/businessProcess/WaterMeterReinstallation.vue'
          ),
      },
      {
        //"水表销户"
        path: 'CancellationWaterMeterAccount',
        name: 'CancellationWaterMeterAccount',
        component: () =>
          import(
            '@/view/online_business/businessProcess/CancellationWaterMeterAccount.vue'
          ),
      },
      {
        //"用水性质变更"
        path: 'ChangeWaterUse',
        name: 'ChangeWaterUse',
        component: () =>
          import('@/view/online_business/businessProcess/ChangeWaterUse.vue'),
      },
      {
        //"用户性质变更"
        path: 'ChangeUser',
        name: 'ChangeUser',
        component: () =>
          import('@/view/online_business/businessProcess/ChangeUser.vue'),
      },
      // 水务宣传
      {
        //"水务宣传"
        path: 'waterPublicity',
        name: 'waterPublicity',
        component: () =>
          import('@/view/online_business/waterPublicity/waterPublicity.vue'),
      },
      //报装管理
      {
        //"报装管理"
        path: 'installManage',
        name: 'installManage',
        component: () =>
          import('@/view/online_business/installManage/index.vue'),
      },
      // 报修管理
      {
        //"报修管理"
        path: 'fixManage',
        name: 'fixManage',
        component: () => import('@/view/online_business/fixManage/index.vue'),
      },
      // 消息模板
      {
        //"消息模板"
        path: 'msgTemplate',
        name: 'msgTemplate',
        component: () => import('@/view/online_business/msgTemplate/index.vue'),
      },
      // 短信发送记录
      {
        //"短信发送记录"
        path: 'msgSendRecord',
        name: 'msgSendRecord',
        component: () =>
          import('@/view/online_business/msgSendRecord/msgSendRecord.vue'),
      },
      // 营业所管理
      {
        //"营业所管理"
        path: 'businessHallQuery',
        name: 'businessHallQuery',
        component: () =>
          import('@/view/online_business/businessHallQuery/index.vue'),
      },
      // 检测报告
      {
        //"检测报告"
        path: 'checkReport',
        name: 'checkReport',
        component: () => import('@/view/online_business/checkReport/index.vue'),
      },
    ],
  },
]
