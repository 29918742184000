import request_url from '@/api/global.js';
import router from '@/router';
import axios from 'axios';
import { Message } from 'element-ui';
import { debounce } from 'lodash';

const debouncedWarning = debounce(Message.warning, 200)


const request = axios.create({
  baseURL: request_url,
  timeout: 20000,
})

// 请求拦截器
request.interceptors.request.use(
  (config) => {
    config.headers.Authorization = 'Bearer ' + localStorage.getItem('token')
    return config
  },
  (error) => {
    return Promise.reject(error)
  }
)

// 响应拦截器
request.interceptors.response.use(
  (response) => {
    // 刷新 token
    const newToken = response.headers['authorization']
    if (newToken) {
      localStorage.setItem('token', newToken.replace('Bearer ', ''))
    }

    if (response.data.code < 400) {
      return { ...response.data, success: true }
    } else if (response.data.code == 404) {
      // token过期的情况
      router.push({ name: 'login' })
    }

    debouncedWarning(response.data.message)
    return { ...response.data, success: false }
  },
  (error) => {
    console.log(error)
  }
)

// 暴露 Axios 实例
export default request
