import Vue from 'vue'
import Vuex from 'vuex'
import gis from './modules/gis.js'
import tab from './modules/tab.js'
import cockpit from './modules/cockpit.js'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    adId: 25, // 高唐县行政区划id
    topMenu: JSON.parse(localStorage.getItem('topMenu') || '{}'),
    currentUser: JSON.parse(localStorage.getItem('currentUser') || '{}'),
    adList: JSON.parse(localStorage.getItem('adList') || '{}'),
    waterPriceEnum: JSON.parse(localStorage.getItem('waterPriceEnum') || '{}'),
    waterPriceTypeEnum: JSON.parse(
      localStorage.getItem('waterPriceTypeEnum') || '{}'
    ),
    deviceProducerEnum: JSON.parse(
      localStorage.getItem('deviceProducerEnum') || '{}'
    ),
    sysOrganizationEnum: JSON.parse(
      localStorage.getItem('sysOrganizationEnum') || '{}'
    ),
  },
  mutations: {
    // 非异步请求的方法,commit使用
    addTopMenu(state, data) {
      state.topMenu = data
      localStorage.setItem('topMenu', JSON.stringify(data))
    },
    loadCurrentUser(state, data) {
      state.currentUser = data
      localStorage.setItem('currentUser', JSON.stringify(data))
    },
    loadAdDeviceProducer(state, data) {
      state.deviceProducerEnum = data
      localStorage.setItem('deviceProducerEnum', JSON.stringify(data))
    },
    loadAdList(state, data) {
      state.adList = data
      localStorage.setItem('adList', JSON.stringify(data))
    },
    loadWaterPrice(state, data) {
      state.waterPriceEnum = data
      localStorage.setItem('waterPriceEnum', JSON.stringify(data))
    },
    loadWaterPriceType(state, data) {
      state.waterPriceTypeEnum = data
      localStorage.setItem('waterPriceTypeEnum', JSON.stringify(data))
    },
    loadSysOrganization(state, data) {
      state.sysOrganizationEnum = data
      localStorage.setItem('sysOrganizationEnum', JSON.stringify(data))
    },
	changePosition(state,position) {
	  state.position = position
	},
	setPlaceId(state,placeId) {
	  state.placeId = placeId
	}
  },
  actions: {},
  modules: {
    tab,
    gis,
    cockpit,
  },
})
