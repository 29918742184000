import request from '@/api/axios.js'

// sw - 行政区划 - 行政区划信息搜索 - 树状结构
export function queryAllSystemAd(parentIds) {
  const url = `/sw/systemAd/getAllSystemAd/${parentIds}`
  return request.get(url)
}

// 根据行政区划parentId查询儿子区域图层信息
export function getLayerByParentId(parentId) {
  const url = `/sw/system_layer/${parentId}`
  return request.get(url)
}

// 报警记录分类查询
export function pageMsgAlarmHis(data) {
  const url = `/sw/msg_alarm_his/page_msg_alarm_his`
  return request.post(url, data)
}

/* 根据工程id查询用户水表信息 */
export function getWaterUserDiagram(id) {
  const url = `/sw/water_user_diagram/${id}`
  return request.get(url)
}

// sw - 水费账单 - 根据 水表号+年份+月份 查询日用水量以及相关水表日总金额(单一月份)
export function getDayFlow(deviceAddress, year, month) {
  const url = `/sw/waterBillMonth/day_flow_money/${deviceAddress}/${year}/${month}`
  return request.get(url)
}

// sw - 水费账单 - 根据水表号查询月用水量以及相关水表月总金额
export function getMonthFlow(deviceAddress) {
  const url = `/sw/waterBillMonth/${deviceAddress}`
  return request.get(url)
}

// 根据户号查询下属水表号
export function getWaterDeviceByDoorNo(doorNo) {
  const url = `/sw/waterDeviceUser/getWaterDeviceUserByDoorNo/${doorNo}`
  return request.get(url)
}

// 根据户号查询下属水表号
export function getWaterDeviceUserByDoorNo(doorNo) {
  const url = `/sw/waterDeviceUser/getWaterDeviceUserByDoorNo/${doorNo}`
  return request.get(url)
}

// sw - 收费管理 - 通过户号查询减免记录
export function queryByDoorNo(doorNo) {
  const url = `/sw/derateWaterRecord/${doorNo}`
  return request.get(url)
}

// sw - 水费账单 - 通过户号查询某年的缴费情况
export function queryWaterPayRecordByDoorNo(doorNo) {
  const url = `/sw/waterPayRecord/queryWaterPayRecordByDoorNo/${doorNo}`
  return request.get(url)
}

// sw - 水费账单 - 数据回显某条水费账单信息
export function queryWaterBillDto(id) {
  const url = `/sw/waterBillMonth/queryWaterBillDto/${id}`
  return request.get(url)
}

// sw - 水费账单 - 根据户号查询用水账单数据
export function queryUseWaterBillByDoorNo(doorNo,year) {
  const url = `/sw/waterBillMonth/getUseWaterBillByDoorNo/${doorNo}/${year}`
  return request.get(url)
}

// sw - 水费账单 - 根据户号查询用水账单数据
export function getUseWaterBillByDoorNo(doorNo) {
  const url = `/sw/waterBillMonth/getUseWaterBillByDoorNo/${doorNo}`
  return request.get(url)
}

// 控制发送阀门开关指令
export function valveControl(data) {
  const url = '/sw/deviceCtrlLog/valveControl'
  return request.post(url, data)
}

// 工程类型列表查询
export function getProjectType() {
  const url = '/sw/projectType/queryAll'
  return request.get(url)
}

// 根据行政区划id + 工程类型id查询工程的信息
export function getProjectDto(data) {
  const url = '/sw/project/project_dto'
  return request.post(url, data)
}

export function getProject(data) {
  const url = `/sw/project/getProjectList`
  return request.post(url, data)
}

// gis地图管线查询
export function getSystemAdLayerByAdId(adId) {
  const url = `/sw/system_ad_layer/${adId}`
  return request.get(url)
}
export function getUserInfoDetialById(data) {
  const url = '/omc/system_account/getUserInfoDetialById'
  return request.post(url, data)
}
export function getWaterUserByDoorNo(doorNo) {
  const url = `/sw/waterUser/${doorNo}`;
  return request.get(url);
}
export function getYearFeeStatistics(data) {
  const url = "/sw/waterPayRecord/year_fee_statistics";
  const headers = {
    "Content-Type": "application/form-data"
  };
  return request.post(url, data, { headers });
}