import Vue from "vue";
import ElementUI from "element-ui";
import "element-ui/lib/theme-chalk/index.css";
import "video.js/dist/video-js.css";
import BMap from "vue-baidu-map";
import App from "./App.vue";
import router from "./router";
import Print from "vue-print-nb";
import "@/assets/css/common.css";
import "./assets/css/color.css";
import "./assets/css/reset.css";
import store from "./store";
import tinymce from "tinymce";
import '@/utils/dialogDrag.js';
import '@/static/css/mars.css'
import '@/static/css/graphic.css'


Vue.prototype.$tinymce = tinymce;

Vue.use(Print);

Vue.config.productionTip = false;
// 尝试从 sessionStorage 恢复最后访问的路由
const lastRoute = sessionStorage.getItem('lastRoute');
if (lastRoute) {
  const parsedLastRoute = JSON.parse(lastRoute);
  router.replace({ ... parsedLastRoute}).catch(() => {}); // 忽略可能的错误，例如导航重复
}
//判断浏览器是否进行刷新
if (!sessionStorage.getItem('hasVisited')) {	//首次加载页面
  sessionStorage.setItem('hasVisited', true);
} else {	//页面被刷新
  sessionStorage.setItem('refresh', true);
}

Vue.use(ElementUI, { size: "small" });
Vue.use(BMap, { ak: "bUtTW2eTWlxKnuGLpCaLdjWjPvkNdNy5" });

new Vue({
  el: "#app",
  router,
  store,
  render: h => h(App) // 使用渲染函数代替 template
});
